<script>
import {
    ArrowUpIcon,
    ChevronRightIcon,
    UserIcon,
    MailIcon,
    MessageCircleIcon,
    PlayIcon
} from 'vue-feather-icons';
import {
    Carousel,
    Slide
} from 'vue-carousel';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Index-hotel component
 */
export default {
    data() {
        return {
            slide: 0,
            date: null,
            checkoutdate: null
        }
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        Carousel,
        Slide,
        ArrowUpIcon,
        ChevronRightIcon,
        UserIcon,
        MailIcon,
        MessageCircleIcon,
        PlayIcon,
        flatPickr
    }
}
</script>

<template>
<div>
    <Navbar :is-white-navbar="true" />

    <!-- Hero Start -->
    <section class="main-slider">
        <b-carousel fade id="carousel-1" v-model="slide" class="slides">
            <b-carousel-slide class="bg-slider bg-animation-left d-flex align-items-center" :style="{'background-image':'url(' + require('../../public/images/hotel/bg01.jpg')+ ')'}">
                <div class="container">
                    <div class="row text-left text-dark">
                        <div class="col-lg-7 col-md-7">
                            <div class="title-heading position-relative mt-4" style="z-index: 1;">
                                <h1 class="heading mb-3">Enjoy Better Holidays With Landrick Resort</h1>
                                <p class="para-desc">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page.</p>
                                <div class="watch-video mt-4 pt-2">
                                    <a href="javascript: void(0);" v-b-modal.modal class="video-play-icon watch text-dark"><i class="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-circle text-white title-dark position-relative play play-iconbar"></i> WATCH VIDEO</a>
                                </div>
                                <b-modal id="modal" hide-footer size="lg" header-close-variant="white" header-class="border-0" content-class="border-0" centered>
                                    <youtube video-id="jNTZpfXYJa4" ref="youtube" width="750" height="450"></youtube>
                                </b-modal>
                            </div>
                        </div>
                    </div>
                </div>
            </b-carousel-slide>
            <b-carousel-slide class="bg-slider bg-animation-left d-flex align-items-center" :style="{'background-image':'url(' + require('../../public/images/hotel/bg02.jpg')+ ')'}">
                <div class="container">
                    <div class="row text-left text-dark">
                        <div class="col-lg-7 col-md-7">
                            <div class="title-heading position-relative mt-4" style="z-index: 1;">
                                <h1 class="heading mb-3">Enjoy The World of Relaxation</h1>
                                <p class="para-desc">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page.</p>
                                <div class="watch-video mt-4 pt-2">
                                    <router-link to="/page-aboutus" class="btn btn-primary"><i class="mdi mdi-briefcase"></i> Aboutus</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-carousel-slide>
            <b-carousel-slide class="bg-slider bg-animation-left d-flex align-items-center" :style="{'background-image':'url(' + require('../../public/images/hotel/bg03.jpg')+ ')'}">
                <div class="container">
                    <div class="row text-left text-dark">
                        <div class="col-lg-7 col-md-7">
                            <div class="title-heading position-relative mt-4" style="z-index: 1;">
                                <h1 class="heading mb-3">Welcome Landrick <br> A Stunning Hotel</h1>
                                <p class="para-desc">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page.</p>
                                <div class="mt-4 pt-2">
                                    <a v-scroll-to="'#bookroom'" class="btn btn-primary mouse-down"><i class="mdi mdi-home-assistant"></i> Book now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-carousel-slide>
        </b-carousel>
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Partners start -->
    <section class="section-two bg-light" id="bookroom">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <form class="p-4 shadow bg-white rounded">
                        <h4 class="mb-3">Book Now !</h4>
                        <div class="row text-left">
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label> Check in : </label>
                                    <flat-pickr v-model="date" class="flatpickr flatpickr-input form-control" id="checkin-date" name="date">
                                    </flat-pickr>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label> Check out : </label>
                                    <flat-pickr v-model="checkoutdate" class="flatpickr flatpickr-input form-control" id="checkout-date" name="date">
                                    </flat-pickr>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-lg-6">
                                <div class="row align-items-center">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Adults : </label>
                                            <input type="number" min="0" autocomplete="off" id="adult" required="" class="form-control" placeholder="Adults :">
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Children : </label>
                                            <input type="number" min="0" autocomplete="off" id="children" class="form-control" placeholder="Children :">
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-md-4 mt-2">
                                        <input type="submit" id="search" name="search" class="searchbtn btn btn-primary btn-block p" value="Search">
                                    </div>
                                    <!--end col-->
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Partners End -->

    <!-- Rooms Start -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Rooms & Suits</h4>
                        <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/01.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Regular Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>1 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>1 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$350</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/02.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Regular Big Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>1 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>1 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$800</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/03.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Deluxe Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>1 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>2 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$1100</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/04.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Super Deluxe Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>2 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>2 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$1400</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/05.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Deluxe Big Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>3 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>3 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$1600</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/06.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Family Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>3 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>2 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$1750</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/07.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Premium Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>1 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>1 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$1890</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/08.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Super Premium Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>1 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>1 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$2050</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card work-container work-modern rounded border-0 overflow-hidden">
                        <div class="card-body p-0">
                            <img src="images/hotel/09.jpg" class="img-fluid rounded" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content">
                                <a href="javascript:void(0);" class="title text-white pb-2 border-bottom">Premium Big Room</a>
                                <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                                    <li class="list-inline-item mr-3"><i class="mdi mdi-bed-empty mdi-24px mr-2"></i>1 Bed</li>
                                    <li class="list-inline-item"><i class="mdi mdi-scale-bathroom mdi-24px mr-2"></i>1 Bathrooms</li>
                                </ul>
                                <p class="text-white d-block mb-0">Rent <span class="text-success">$2500</span> /Night</p>
                            </div>
                            <div class="read_more bg-primary text-center rounded-circle">
                                <a href="javascript:void(0);" class="text-light d-block">
                                    <chevron-right-icon class="fea icon-sm title-dark"></chevron-right-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row justify-content-center">
                <div class="col-12 text-center mt-4 pt-2">
                    <a href="javascript:void(0);" class="btn btn-primary">See More <i class="mdi mdi-chevron-right"></i></a>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Rooms End -->

    <!-- CTA Start -->
    <section class="section bg-cta" style="background: url('images/hotel/bg04.jpg') center center;" id="cta">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h4 class="title title-dark text-white mb-4">Resembling Tour of Landrick Resort</h4>
                        <p class="text-light para-dark para-desc mx-auto">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        <a href="javascript: void(0);" v-b-modal.modal-1 class="play-btn border border-light mt-4 video-play-icon">
                            <play-icon class="fea icon-ex-md text-white title-dark"></play-icon>
                        </a>
                        <b-modal id="modal-1" hide-footer size="lg" header-close-variant="white" header-class="border-0" content-class="border-0" centered>
                            <vimeo-player ref="player" :player-width="750" :player-height="450" :video-id="287684225" />
                        </b-modal>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- CTA End -->

    <!-- Services Start -->
    <section class="section">
        <div class="container pb-lg-4 mb-md-5 mb-4">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Best Services for you</h4>
                        <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-md-4 col-12 mt-5">
                    <div class="features text-center">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/wifi.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Free WIFI</h4>
                            <p class="text-muted mb-0">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-5">
                    <div class="features text-center">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/game-controller.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Relaxation</h4>
                            <p class="text-muted mb-0">Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-5">
                    <div class="features text-center">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/weightlifter.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Spa & Fitness</h4>
                            <p class="text-muted mb-0">It seems that only fragments of the original text remain in the Lorem Ipsum fragments texts used today.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-5">
                    <div class="features text-center">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/serving-dish.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Restaurant</h4>
                            <p class="text-muted mb-0">It seems that only fragments of the original text remain in the Lorem Ipsum fragments texts used today.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-5">
                    <div class="features text-center">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/cardiogram.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Smooth Parallax</h4>
                            <p class="text-muted mb-0">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-5">
                    <div class="features text-center">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/bed.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Bedrooms</h4>
                            <p class="text-muted mb-0">Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-light">
            <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- End services -->

    <!-- News Start -->
    <section class="section pt-5 pt-sm-0 pt-md-4 bg-light">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Latest News</h4>
                        <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card blog rounded border-0 shadow">
                        <div class="position-relative">
                            <img src="images/hotel/bg01.jpg" class="card-img-top rounded-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a href="javascript:void(0);" class="card-title title text-dark">Design your apps in your own way</a></h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0);" class="text-muted like"><i class="mdi mdi-heart-outline mr-1"></i>33</a></li>
                                    <li class="list-inline-item"><a href="javascript:void(0);" class="text-muted comments"><i class="mdi mdi-comment-outline mr-1"></i>08</a></li>
                                </ul>
                                <router-link to="/page-blog-detail" class="text-muted readmore">Read More <i class="mdi mdi-chevron-right"></i></router-link>
                            </div>
                        </div>
                        <div class="author">
                            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small>
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 13th August, 2019</small>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card blog rounded border-0 shadow">
                        <div class="position-relative">
                            <img src="images/hotel/bg02.jpg" class="card-img-top rounded-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a href="javascript:void(0);" class="card-title title text-dark">How apps is changing the IT world</a></h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0);" class="text-muted like"><i class="mdi mdi-heart-outline mr-1"></i>33</a></li>
                                    <li class="list-inline-item"><a href="javascript:void(0);" class="text-muted comments"><i class="mdi mdi-comment-outline mr-1"></i>08</a></li>
                                </ul>
                                <router-link to="/page-blog-detail" class="text-muted readmore">Read More <i class="mdi mdi-chevron-right"></i></router-link>
                            </div>
                        </div>
                        <div class="author">
                            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small>
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 13th August, 2019</small>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card blog rounded border-0 shadow">
                        <div class="position-relative">
                            <img src="images/hotel/bg03.jpg" class="card-img-top rounded-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a href="javascript:void(0);" class="card-title title text-dark">Smartest Applications for Business</a></h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0);" class="text-muted like"><i class="mdi mdi-heart-outline mr-1"></i>33</a></li>
                                    <li class="list-inline-item"><a href="javascript:void(0);" class="text-muted comments"><i class="mdi mdi-comment-outline mr-1"></i>08</a></li>
                                </ul>
                                <router-link to="/page-blog-detail" class="text-muted readmore">Read More <i class="mdi mdi-chevron-right"></i></router-link>
                            </div>
                        </div>
                        <div class="author">
                            <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small>
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 13th August, 2019</small>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Blog End -->

    <!-- Client Start -->
    <section class="section" style="background: url('images/hotel/bg05.jpg') center center;">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 text-center">
                    <carousel id="owl-fade" class="owl-carousel owl-theme" dir="ltr" :per-page="1">
                        <Slide>
                            <div class="customer-testi text-center">
                                <p class="text-light para-dark h6 font-italic">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "</p>
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-light title-dark"> Thomas Israel </h6>
                                <img src="images/client/01.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                            </div>
                            <!--end customer testi-->
                        </Slide>
                        <Slide>
                            <div class="customer-testi text-center">
                                <p class="text-light para-dark h6 font-italic">" The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout. "</p>
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-light title-dark"> Carl Oliver </h6>
                                <img src="images/client/02.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                            </div>
                            <!--end customer testi-->
                        </Slide>
                        <Slide>
                            <div class="customer-testi text-center">
                                <p class="text-light para-dark h6 font-italic">" There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories. "</p>
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-light title-dark"> Barbara McIntosh </h6>
                                <img src="images/client/03.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                            </div>
                            <!--end customer testi-->
                        </Slide>
                        <Slide>
                            <div class="customer-testi text-center">
                                <p class="text-light para-dark h6 font-italic">" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling all the instances of the unusual word 'consectetur' he could find "</p>
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-light title-dark"> Christa Smith </h6>
                                <img src="images/client/04.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                            </div>
                            <!--end customer testi-->
                        </Slide>
                        <Slide>
                            <div class="customer-testi text-center">
                                <p class="text-light para-dark h6 font-italic">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "</p>
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-light title-dark"> Dean Tolle </h6>
                                <img src="images/client/05.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                            </div>
                            <!--end customer testi-->
                        </Slide>
                        <Slide>
                            <div class="customer-testi text-center">
                                <p class="text-light para-dark h6 font-italic">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "</p>
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-light title-dark"> Jill Webb </h6>
                                <img src="images/client/06.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                            </div>
                            <!--end customer testi-->
                        </Slide>
                    </carousel>
                    <!--end owl carousel-->
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
        <!-- Client End -->
    </section>
    <!-- Client End -->

    <!-- Contact Start -->
    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-6 p-0 pl-md-3 pr-md-3">
                    <div class="card map map-height-two rounded map-gray border-0">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style="border:0" class="rounded" allowfullscreen></iframe>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card rounded shadow border-0">
                        <div class="card-body py-5">
                            <h5 class="card-title">Get In Touch !</h5>

                            <div class="custom-form mt-4">
                                <div id="message"></div>
                                <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group position-relative">
                                                <label>Your Name <span class="text-danger">*</span></label>
                                                <user-icon class="fea icon-sm icons"></user-icon>
                                                <input name="name" id="name" type="text" class="form-control pl-5" placeholder="First Name :">
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-md-12">
                                            <div class="form-group position-relative">
                                                <label>Your Email <span class="text-danger">*</span></label>
                                                <mail-icon class="fea icon-sm icons"></mail-icon>
                                                <input name="email" id="email" type="email" class="form-control pl-5" placeholder="Your email :">
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-md-12">
                                            <div class="form-group position-relative">
                                                <label>Comments</label>
                                                <message-circle-icon class="fea icon-sm icons"></message-circle-icon>
                                                <textarea name="comments" id="comments" rows="4" class="form-control pl-5" placeholder="Your Message :"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end row-->
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                            <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary btn-block" value="Send Message">
                                            <div id="simple-msg"></div>
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <!--end row-->
                                </form>
                                <!--end form-->
                            </div>
                            <!--end custom-form-->
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
        <!-- Contact End -->
    </section>
    <!--end section-->
    <!-- News End -->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style>
.modal-content {
    background-color: transparent;
}

::v-deep .carousel-caption {
    display: contents;
}

.carousel-caption {
    width: auto;
    height: auto !important;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    position: unset !important;
}
</style>
